import React from "react"
import PropTypes from "prop-types"
import { Grid, Box, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"

import { Container, Section, HammerBreak } from "../Layout"
import { Hero } from "../Hero"

import { StyledMap } from "./Map.styled"

import HeroHome from "../../images/hero-home.svg"

const Map = ({ children }) => {
  return (
    <>
      <StyledMap>
        <Box>
          <Hero>
            <Container>
              <Box>
                <Grid container>
                  <Grid item md={2}></Grid>
                  <Grid item md={8}>
                    <Section>
                      <Box
                        display="block"
                        component="img"
                        src={HeroHome}
                        alt="bos logo"
                        mb={2}
                        align="center"
                        sx={{ maxWidth: `300px`, margin: `0 auto` }}
                      />
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        align="center"
                        gutterBottom
                      >
                        Map of Yrub Silas
                      </Typography>
                    </Section>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Box>
            </Container>
          </Hero>
        </Box>
        <Box>
          <Container>
            <Box display="flex">
              <StaticImage
                src="../../images/yrub-silas-map.jpg"
                alt="Yrub Silas Map"
                placeholder="blurred"
              />
            </Box>
          </Container>
        </Box>
      </StyledMap>
    </>
  )
}

Map.propTypes = {
  children: PropTypes.node,
}

export default Map
